<template>
  <el-card class="bgcard">
    <div class="headerMsg">
      <div class="addbtn">
        <button @click="addrolefore">+ {{ $t("lang.Addrole") }}</button>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column :label="$t('lang.Rolename')" align="center">
					<template #default="scope">
						<span>{{scope.row.name |text}}</span>
					</template>
        </el-table-column>
        <el-table-column :label="$t('lang.Roledescription')" align="center">
					<template #default="scope">
						<span>{{scope.row.desc |text}}</span>
					</template>
        </el-table-column>
        <el-table-column :label="$t('lang.operation')" align="center">
          <template #default="scope">
            <div class="bottom_tabes" v-if="scope.row.name != '超级管理员'">
              <el-tooltip
                effect="dark"
                :content='$t("lang.edit")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="showuser(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.deletes")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_delete"
                   @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="deletes(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    src="@/assets/imgs/bottom/shanchu.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                   v-if="scope.row.flag2 === 1"
                    src="@/assets/imgs/bottom/shanchu2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button
              v-if="scope.row.name != '超级管理员'"
              class="buttons"
              style="background-color: #307b37"
              size="mini"
              @click="showuser(scope.row)"
              >{{ $t("lang.edit") }}</el-button
            >
            <el-button
              v-if="scope.row.name != '超级管理员'"
              class="buttons"
              style="background-color: #1061c5"
              size="mini"
              @click="deletes(scope.row)"
              >{{ $t("lang.deletes") }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增、编辑角色权限 -->
    <el-dialog
      :title="roleTitle"
      :visible.sync="edituser"
      custom-class="showedituser"
			:width="widths"
      :show-close="false"
    >
      <div class="edituser">
        <div class="edituser_a">
          <label>{{ $t("lang.Rolename") }}：</label>
          <el-input v-model="jurisdiction.name"></el-input>
        </div>
        <div class="edituser_a">
          <label>{{ $t("lang.Roledescription") }}：</label>
          <el-input v-model="jurisdiction.desc"></el-input>
        </div>
        <div class="juchoice">
          <div class="edituser_a">{{ $t("lang.Permissionselection") }}：</div>
          <div class="bgcards">
            <el-tree
              :data="initPrivsList"
              :show-checkbox="true"
              :props="props"
              node-key="id"
              :expand-on-click-node="false"
              :default-expand-all="true"
              @check-change="handleCheckChange"
              :default-checked-keys="initList"
              ref="tree"
            >
            </el-tree>
          </div>
        </div>
      </div>
      <div class="el-dialog-footer">
        <button @click="edituser = false">{{ $t("lang.cancel") }}</button>
        <button @click="okclick">{{ $t("lang.determine") }}</button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as roleList from "@/api/roleList.js";
import bus from "@/utils/bus.js";
export default {
  data() {
    return {
      seenOnetext: this.$t("lang.binding"),
      seenTwotext: this.$t("lang.Unbundling"),
      seenFreetext: this.$t("lang.details"),
      seenOne: true,
      seenTwo: true,
      seenThree: true,
      seenFree: true,
      seenFive: true,

      edituser: false,
      user: JSON.parse(sessionStorage.getItem("user")),
      tableData: [], //权限列表数据
      roleTitle: "",
      initPrivsList: [], //权限数据
      props: {
        label: "name",
      },
      roleIds: [], //选中的权限
      initList: [], //用户拥有的权限
      jurisdiction: {
        name: "",
        desc: "",
        priv_ids: "",
        id: "",
      },
      figen: "",
			langs:false,
			widths:''
    };
  },
  created() {
    bus.$on("langchange", (params) => {
      params === "zhong" ? (this.props.label = "name") : (this.props.label = "languages");
    });
		if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
			this.langs=true
			this.widths='57%'
		}else{
			this.langs=false
			this.widths='82%'
		}
  },
	filters:{
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val
				if(zhon=='zhong'){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
				text=sk
			}
			return text
		}
	},
  mounted() {
		bus.$on("langchange", (params) => {
		  params === "zhong" ? (this.props.label = "name") : (this.props.label = "languages");
			if(params === "zhong"){
				this.widths='57%'
				this.langs=true
			}else{
				this.widths='82%'
				this.langs=false
			}
		});
    this.roleList();
    this.initPriv();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData) {
        if (index === 1 ) {
          if(ids == this.tableData[i].id && flag === 0){
            this.tableData[i].flag1 = 1
          }
        }
         if (index === 2 ) {
          if(ids == this.tableData[i].id && flag === 0){
            this.tableData[i].flag2 = 1
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
       for (let i in this.tableData) {
        if (index === 1 ) {
          if(ids == this.tableData[i].id && flag === 1){
            this.tableData[i].flag1 = 0
          }
        }
         if (index === 2 ) {
          if(ids == this.tableData[i].id && flag === 1){
            this.tableData[i].flag2 = 0
          }
        }
      }
    },

    // 选中权限
    handleCheckChange(data, checked) {
      if (checked) {
        this.roleIds.push(data.id);
      } else {
        for (let i in this.roleIds) {
          if (this.roleIds[i] === data.id) {
            this.roleIds.splice(i, 1);
          }
        }
      }
    },
    //添加角色
    addrolefore() {
      this.figen = 1;
      this.roleTitle = this.$t("lang.Addrole");
      this.initList = [];
      this.jurisdiction = {
        name: "",
        desc: "",
        priv_ids: "",
      };
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
      });
      this.edituser = true;
    },
    //编辑角色
    async showuser(row) {
      this.figen = 2;
      this.jurisdiction = {
        name: "",
        desc: "",
        priv_ids: "",
        id: "",
      };
      this.initList = [];
      this.roleTitle = this.$t("lang.Editorialrole");
      this.edituser = true;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
      });
      let res = await roleList.initPrivs_a({ role_id: row.id });
      //console.log(res)
      if (res) {
        res.forEach((item) => {
          this.initList.push(item.id);
        });
      }
			
			let sk=row.name
			if(this.langs){
				let wq=sk.split('-')
				sk=wq[0]
			}else{
				let wq=sk.split('-')
				sk=wq[1]
				if(wq.length==1){
					sk=wq[0]
				}
			}
      this.jurisdiction.name = sk;
			
			let skas=row.desc
			if(skas){
				if(this.langs){
					let wq=skas.split('-')
					skas=wq[0]
				}else{
					let wq=skas.split('-')
					skas=wq[1]
					if(wq.length==1){
						skas=wq[0]
					}
				}
			}
			
      this.jurisdiction.desc = skas;
      this.jurisdiction.id = row.id;
    },
    // 获取角列表
    async roleList() {
      let res = await roleList.list();
      if (res) {
        console.log(res,"88888888888888");
         res.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
        });
        this.tableData = res;
        
      }
    },
    // 获取权限列表
    async initPriv() {
      let res = await roleList.initPrivs();
      if (res) {
        let arrs = [];
        res.forEach((item) => {
          item.children = [];
          if (item.id && !item.parentId) {
            arrs.push(item);
          }
        });
        arrs.forEach((item) => {
					let sk=item.name
					if(this.langs){
						let wq=sk.split('-')
						sk=wq[0]
					}else{
						let wq=sk.split('-')
						sk=wq[1]
						if(wq.length==1){
							sk=wq[0]
						}
					}
					item.name=sk
          res.forEach((ims) => {
            if (item.id === ims.parentId) {
              item.children.push(ims);
            }
						let skda=ims.name
						if(this.langs){
							let wq=skda.split('-')
							skda=wq[0]
						}else{
							let wq=skda.split('-')
							skda=wq[1]
							if(wq.length==1){
								skda=wq[0]
							}
						}
						ims.name=skda
          });
        });
        console.log(arrs);
				
        this.initPrivsList = arrs;
      }
    },
    //提交参数
    async okclick() {
      if (this.jurisdiction.name == "") {
        this.$message.error(this.$t("lang.Pleasefillintherolename"));
        return false;
      }
      if (this.roleIds.length < 1) {
        this.$message.error(this.$t("lang.Pleaseselectpermission"));
        return false;
      }
      //添加
      this.jurisdiction.priv_ids = this.roleIds.join(",");
      if (this.figen === 1) {
        let data = {
          name: this.jurisdiction.name,
          desc: this.jurisdiction.desc,
          priv_ids: this.jurisdiction.priv_ids,
        };
        console.log(data);
        let res = await roleList.addrole(data);
        console.log(res);
        if (res) {
          this.$message.success(this.$t("lang.addsuccess"));
          this.edituser = false;
          this.roleList();
        }
      } else {
        //编辑
        roleList
          .edit({
            desc: this.jurisdiction.desc,
            name: this.jurisdiction.name,
            id: this.jurisdiction.id,
          })
          .then(() => {
            //console.log(res)
            this.edig();
          });
      }
    },
    //修改角色对应的权限列表
    edig() {
      roleList
        .edit_a({
          priv_ids: this.jurisdiction.priv_ids,
          role_id: this.jurisdiction.id,
        })
        .then((res) => {
          console.log(res);
          this.edituser = false;
          this.$message.success(this.$t("lang.Editorsuccess"));
          this.roleList();
        });
    },
    //删除角色
    deletes(row) {
      this.$confirm(
        this.$t("lang.Thisoperationwillpermanentlydelete"),
        this.$t("lang.Tips"),
        {
          confirmButtonText: this.$t("lang.determine"),
          cancelButtonText: this.$t("lang.cancel"),
          type: "warning",
        }
      ).then(() => {
        roleList.deletes({ id: row.id }).then((res) => {
          console.log(res);
          this.$message.success(this.$t("lang.deletessuccess"));
          this.roleList();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    button {
      height: 0.45rem;
      border: none;
      outline-style: none;
      padding: 0rem 0.1rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>
